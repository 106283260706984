import React from 'react';
import ProfessionalForm from '../components/ProfessionalForm/professional-form';

const ProfessionalFormPage = props => {
  return (
    <ProfessionalForm />
  );
};

export default ProfessionalFormPage;
